import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { colors, breakpoint } from '../../theme';

import Menu from '../Menu';
import Button from '../Button';

const TopBar = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: ${(props) => props.theme.palette.background.body}; */
  padding: 0.94rem 0;

  ${breakpoint('large')} {
    padding: ${(props) => props.theme.spacing.gutter} 0;
  }
`;

const LogoCol = styled.div`
  display: flex;
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-shrink: 0;
  align-items: center;
`;

const NavigationColStart = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 1;
  flex-shrink: 1;
  display: none;

  ${breakpoint('large')} {
    display: block;
  }
`;

const NavigationColEnd = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 1;
  flex-shrink: 1;
  display: none;
  justify-content: flex-end;
  align-items: center;

  ${breakpoint('large')} {
    display: flex;
  }
`;

const ToggleCol = styled.div`
  margin: 0;
  padding-left: ${(props) => `${props.theme.spacing.columnGutter}`};
  padding-right: ${(props) => `${props.theme.spacing.columnGutter}`};
  flex-grow: 0;
`;

const MenuToggle = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0.6rem;
  outline: 0;

  ${breakpoint('large')} {
    display: none;
  }
`;

const ToggleIcon = styled(FontAwesomeIcon)`
  font-size: ${rem(24)};
`;

const CtaCol = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 0;
  display: none;

  ${breakpoint('large')} {
    display: block;
  }
`;

const Logo = styled(Link)`
  display: block;
  color: white;
  text-decoration: none;
  width: ${rem(140)};
  flex-shrink: 0;

  ${breakpoint('large')} {
    width: ${rem(160)};
  }
`;

function Header({
  logo,
  drawerToggle,
  navigationStart,
  navigationEnd,
  ctaButton,
}) {
  return (
    <TopBar>
      <LogoCol>
        <ToggleCol>
          <MenuToggle type="button" onClick={drawerToggle} color="negative">
            <ToggleIcon icon={faBars} color={colors.primary} />
          </MenuToggle>
        </ToggleCol>
        <Logo to="/">{logo}</Logo>
      </LogoCol>
      <NavigationColStart>
        <Menu items={navigationStart} color="negative" />
      </NavigationColStart>

      <NavigationColEnd>
        <Menu items={navigationEnd} color="negative" align="end" />
        {ctaButton.link !== undefined && ctaButton.link && (
          <CtaCol>
            <Button link={ctaButton.link} variant="fill">
              {ctaButton.label}
            </Button>
          </CtaCol>
        )}
      </NavigationColEnd>
    </TopBar>
  );
}

Header.propTypes = {
  logo: PropTypes.node.isRequired,
  drawerToggle: PropTypes.func.isRequired,
  navigationStart: PropTypes.arrayOf(PropTypes.object),
  navigationEnd: PropTypes.arrayOf(PropTypes.object),
  ctaButton: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Header.defaultProps = {
  navigationStart: [],
  navigationEnd: [],
  ctaButton: {},
};

export default Header;

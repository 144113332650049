import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Sticky from 'react-sticky-el';
import { withPreview } from '@digitaliseringsbyran/gatsby-source-prismic-graphql';

import LinkUtil from '../../utils/link';
import LinkFragment from '../../utils/linkFragment';

import Button from '../Button';
import Header from '../Header';
import Footer from '../Footer';
import SiteLogo from '../SiteLogo';
import Drawer from '../Drawer';
import Menu from '../Menu';

const Root = styled.div`
  position: relative;
  /* background-color: ${(props) => props.theme.palette.background.content}; */
`;

const HeaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const StickyWrap = styled(Sticky)`
  transition: box-shadow 0.5s, background-color 0.25s ease-in-out;

  &.stuck {
    background-color: ${(props) => props.theme.palette.background.content};
    /* box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5); */
  }
`;

const Content = styled.div`
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.background.content};

  /* max-width: ${(props) => props.theme.grid.maxWidth};
  padding: 0 1rem 1.5rem; */
`;

const CtaButton = styled.div`
  padding: ${(props) => props.theme.components.menu.itemVertical.padding};
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        logo
      }
    }
    prismic {
      allMultilevel_navigations(lang: "en-gb") {
        edges {
          node {
            body {
              ... on PRISMIC_Multilevel_navigationBody1st_level {
                type
                label
                primary {
                  link_text
                  nav_link {
                    ...LinkFragment
                  }
                }
              }
              ... on PRISMIC_Multilevel_navigationBody2nd_level {
                type
                label
                primary {
                  link_text
                  nav_link {
                    ...LinkFragment
                  }
                }
              }
            }
            _meta {
              uid
              lang
            }
          }
        }
      }
      allSettingss {
        edges {
          node {
            topbar_cta_label
            topbar_cta_link {
              ...LinkFragment
            }
          }
        }
      }
    }
  }
`;

const Layout = ({ children }) => {
  const [drawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!drawerOpen);
  };

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {withPreview(
        ({
          site,
          prismic: {
            allMultilevel_navigations: navigations,
            allSettingss: settings,
          },
        }) => {
          const menus = navigations.edges.reduce(
            (navs, { node: nav }) => ({
              ...navs,
              // eslint-disable-next-line no-underscore-dangle
              [nav._meta.uid]: nav.body.map((item) => ({
                label: item.primary.link_text,
                link: LinkUtil.url(item.primary.nav_link),
              })),
            }),
            {},
          );

          menus.all = [].concat(...Object.values(menus));

          // CTA - button
          const ctaButton = {
            label:
              settings.edges.length && settings.edges[0].node.topbar_cta_label,
            link:
              settings.edges.length && settings.edges[0].node.topbar_cta_link,
          };

          const stickyHeader = false;

          return (
            <Root>
              <Drawer open={drawerOpen} toggle={toggleDrawer}>
                <Menu items={menus.all} direction="vertical" />
                {ctaButton.link !== undefined && ctaButton.link && (
                  <CtaButton>
                    <Button
                      link={ctaButton.link}
                      variant="fill"
                      appearance="negative"
                      style={{ color: '#000' }}
                    >
                      {ctaButton.label}
                    </Button>
                  </CtaButton>
                )}
              </Drawer>

              <HeaderWrap>
                <StickyWrap as={!stickyHeader && 'div'} stickyClassName="stuck">
                  <Header
                    siteTitle={site.siteMetadata.title}
                    logo={<SiteLogo />}
                    drawerToggle={toggleDrawer}
                    navigationStart={menus['main-navigation']}
                    ctaButton={ctaButton}
                  />
                </StickyWrap>
              </HeaderWrap>

              <Content>
                <main>{children}</main>
              </Content>

              <Footer
                siteTitle={site.siteMetadata.title}
                logo={<SiteLogo />}
                // navigation={menus.all}
              />
            </Root>
          );
        },
        query,
        [LinkFragment],
      )}
    </StaticQuery>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { rem } from 'polished';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import BackgroundVideo from 'react-background-video-player';

import { breakpoint } from '../../theme';

const Container = styled(BackgroundImage)`
  position: relative;
  display: inline-table;
  width: 100%;
  /* background-image: url(${(props) => props.fallbackBackground}); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: ${(props) => props.theme.palette.background.body};
  text-align: center;
  min-height: ${rem(380)};

  ${({ big }) =>
    big &&
    `height: ${rem(540)};

    ${breakpoint('mediumlarge')} {
      height: ${rem(600)};
    }
  
    ${breakpoint('large')} {
      height: ${rem(700)};
    }`}
`;

const Tint = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.73);
`;

const Inner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Content = styled.div`
  position: relative;
  /* max-width: ${(props) => props.theme.grid.maxWidthText}; */
  margin: 0 auto;
  color: ${(props) => props.theme.palette.colors.greyLight};
  padding: ${rem(100)} 1.5rem ${rem(50)};
  font-size: ${rem(18)};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(19)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(21)};
    padding-top: ${rem(105)};
  }

  p {
    margin: 0.5rem 0 0 0;
  }
`;

const Actions = styled.div`
  width: 100%;
  margin: 2.5rem auto 0;
`;

const HeroHeading = styled.h2`
  margin: 0;
  font-size: ${rem(36)};
  color: ${(props) => props.theme.palette.colors.white};

  ${breakpoint('medium')} {
    font-size: ${rem(38)};
  }

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(42)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(56)};
  }

  strong,
  b {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const PreHeading = styled.h3`
  margin: 0 0 ${rem(16)} 0;
  font-size: ${rem(14)};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.colors.primary};
  letter-spacing: 0.07em;

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(15)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

const query = graphql`
  query {
    fileName: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

function Hero({
  heading,
  preHeading,
  children,
  actions,
  big,
  image,
  pageTitle,
  videoProps,
}) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ fileName: fallbackBackground }) => {
        return (
          <Container
            Tag="div"
            big={big}
            fluid={
              image?.childImageSharp?.fluid ||
              fallbackBackground?.childImageSharp?.fluid
            }
          >
            <Tint />
            {typeof videoProps.src !== 'undefined' && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <BackgroundVideo {...videoProps} />
            )}
            <Inner>
              <Content>
                {preHeading && <PreHeading>{preHeading}</PreHeading>}
                <HeroHeading as={pageTitle && 'h1'}>{heading}</HeroHeading>
                {children}
                {actions && <Actions>{actions}</Actions>}
              </Content>
            </Inner>
          </Container>
        );
      }}
    </StaticQuery>
  );
}

Hero.propTypes = {
  heading: PropTypes.node.isRequired,
  preHeading: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  big: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pageTitle: PropTypes.bool,
  videoProps: PropTypes.oneOfType([PropTypes.object]),
};

Hero.defaultProps = {
  children: '',
  actions: '',
  preHeading: '',
  big: false,
  image: null,
  pageTitle: false,
  videoProps: {},
};

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from '@digitaliseringsbyran/gatsby-source-prismic-graphql';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faVimeo,
  faPinterest,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import LinkFragment from '../../utils/linkFragment';

const Root = styled.div`
  display: block;
  width: 100%;
`;

const Item = styled.a`
  display: inline-block;
  color: #fff;
  padding: 0.25em;
  line-height: 1;
  /* text-align: center; */
  font-size: ${rem(20)};
  white-space: nowrap;

  ${({ variant }) =>
    variant === 'label' &&
    css`
      /* text-align: left; */
      width: 100%;
    `};
`;

const Label = styled.span`
  display: inline-block;
  padding-left: 0.65em;
  line-height: 1;
  text-align: left;
  font-size: ${rem(15)};
  vertical-align: middle;
`;

const Icon = styled.span`
  display: inline-block;
  vertical-align: middle;
  min-width: 1em;
`;

const query = graphql`
  query {
    prismic {
      allSettingss {
        edges {
          node {
            facebook {
              ...LinkFragment
            }
            instagram {
              ...LinkFragment
            }
            linkedin {
              ...LinkFragment
            }
            pinterest {
              ...LinkFragment
            }
            twitter {
              ...LinkFragment
            }
            youtube {
              ...LinkFragment
            }
            vimeo {
              ...LinkFragment
            }
          }
        }
      }
    }
  }
`;

function SocialLinks({ items, variant }) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {withPreview(
        ({ prismic: { allSettingss: data } }) => {
          const socialLinkData = data.edges.length && data.edges[0].node;

          const socialLinks = {
            facebook: {
              name: 'Facebook',
              icon: <FontAwesomeIcon icon={faFacebook} />,
              url: socialLinkData.facebook && socialLinkData.facebook.url,
            },
            instagram: {
              name: 'Instagram',
              icon: <FontAwesomeIcon icon={faInstagram} />,
              url: socialLinkData.instagram && socialLinkData.instagram.url,
            },
            linkedin: {
              name: 'Linkedin',
              icon: <FontAwesomeIcon icon={faLinkedin} />,
              url: socialLinkData.linkedin && socialLinkData.linkedin.url,
            },
            vimeo: {
              name: 'Vimeo',
              icon: <FontAwesomeIcon icon={faVimeo} />,
              url: socialLinkData.vimeo && socialLinkData.vimeo.url,
            },
            pinterest: {
              name: 'Pinterest',
              icon: <FontAwesomeIcon icon={faPinterest} />,
              url: socialLinkData.pinterest && socialLinkData.pinterest.url,
            },
            youtube: {
              name: 'YouTube',
              icon: <FontAwesomeIcon icon={faYoutube} />,
              url: socialLinkData.youtube && socialLinkData.youtube.url,
            },
          };

          return (
            <Root>
              {items.map((key) => {
                const item = socialLinks[key];
                return (
                  item.url && (
                    <Item
                      key={key}
                      href={item.url}
                      target="_blank"
                      title={item.name}
                      variant={variant}
                    >
                      <Icon>{item.icon}</Icon>
                      {variant === 'label' && <Label>{item.name}</Label>}
                    </Item>
                  )
                );
              })}
            </Root>
          );
        },
        query,
        [LinkFragment],
      )}
    </StaticQuery>
  );
}

SocialLinks.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['label', 'icon']),
};

SocialLinks.defaultProps = {
  items: ['facebook', 'instagram', 'linkedin', 'youtube', 'pinterest', 'vimeo'],
  variant: 'label',
};

export default SocialLinks;

import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import {
  linkResolver,
  withPreview,
} from '@digitaliseringsbyran/gatsby-source-prismic-graphql';
import LinkFragment from '../../utils/linkFragment';

// import Menu from '../Menu';

import Link from '../Link';
import SocialLinks from '../SocialLinks';
import Row from '../Row';
import Col from '../Col';
import Slice from '../Slice';

import { breakpoint } from '../../theme';

const Root = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  padding: ${rem(60)} ${(props) => props.theme.spacing.columnGutter} ${rem(60)};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: auto;

  ${breakpoint('large')} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const MainFooter = styled.footer`
  /* border-top: 1px solid ${(props) => props.theme.global.border.color}; */
  background-color: ${(props) => props.theme.palette.background.body};
  text-align: center;

  ${breakpoint('large')} {
    text-align: left;
  }
`;

const LogoCol = styled(Col)`
  margin: 0;
  width: 100%;
  max-width: ${rem(500)};
  flex-shrink: 0;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.palette.colors.greyLight};
  font-size: ${rem(15)};

  ${breakpoint('large')} {
    margin-bottom: 0;
    max-width: ${rem(330)};
  }

  img {
    width: ${rem(133)};
    margin: 0 auto 1.25rem;

    ${breakpoint('large')} {
      margin: 0 0 1.25rem;
    }
  }
`;

const NavigationCol = styled(Col)`
  margin: 0;
  flex-grow: 1;
  text-align: center;
  margin-bottom: 1rem;
  display: none;

  ${breakpoint('large')} {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;

const SocialCol = styled(Col)`
  margin: 0;
  flex-grow: 0;
  width: 100%;
  max-width: ${rem(220)};
`;

const BottomLine = styled.footer`
  border-top: 1px solid #000;
  display: flex;
  padding-top: ${rem(26)};
  padding-bottom: ${rem(26)};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin: auto;
  color: ${(props) => props.theme.palette.colors.greyLight};
  font-size: ${rem(15)};
  text-align: center;

  ${breakpoint('large')} {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
`;

const BottomLineContainer = styled(Row)`
  width: 100%;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: auto;
`;

const Copyright = styled(Col)`
  width: 100%;

  ${breakpoint('large')} {
    width: 50%;
    text-align: left;
  }
`;

const Terms = styled(Col)`
  width: 100%;

  ${breakpoint('large')} {
    width: 50%;
    text-align: right;
  }

  a {
    color: ${(props) => props.theme.components.menu.item.colorNegative};

    &:hover {
      color: ${(props) => props.theme.components.menu.item.hoverNegative};
    }
  }
`;

const FooterTitle = styled.h5`
  font-size: ${rem(18)};
  margin-bottom: ${rem(12)};
`;

const PreFooter = styled(Row)`
  border-top: 1px solid ${(props) => props.theme.global.border.color};
  padding-top: ${rem(30)};
  padding-bottom: ${rem(30)};
`;

const PreFooterCol = styled(Col)`
  text-align: center;
  margin-bottom: 3.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${breakpoint('large')} {
    margin-bottom: 0;
  }

  /* width: 100%;

  ${breakpoint('large')} {
    width: 50%;
  } */
`;

const query = graphql`
  query {
    prismic {
      allSettingss {
        edges {
          node {
            footer_text
            page_for_privacy_policy {
              ...LinkFragment
            }
            body {
              ... on PRISMIC_SettingsBodyColumn_with_button {
                type
                label
                primary {
                  button_label
                  content
                  button_link {
                    ...LinkFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Footer({ logo }) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {withPreview(
        ({ prismic: { allSettingss: data } }) => {
          const preFooterItems = data.edges.length && data.edges[0].node.body;
          const footerText =
            data.edges.length && data.edges[0].node.footer_text;
          const policyLink =
            data.edges.length && data.edges[0].node.page_for_privacy_policy;
          return (
            <Root>
              {preFooterItems && (
                <PreFooter>
                  <Container>
                    {preFooterItems.map((slice, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <PreFooterCol key={index}>
                        <Slice slice={slice} />
                      </PreFooterCol>
                    ))}
                  </Container>
                </PreFooter>
              )}
              <MainFooter>
                <Container>
                  <LogoCol>
                    {logo}
                    {footerText && (
                      <RichText
                        render={footerText}
                        linkResolver={linkResolver}
                      />
                    )}
                  </LogoCol>
                  <NavigationCol>
                    {/* <Menu items={navigation} align="start" color="negative" /> */}
                  </NavigationCol>
                  <SocialCol>
                    <FooterTitle>Follow us</FooterTitle>
                    <SocialLinks />
                  </SocialCol>
                </Container>
              </MainFooter>
              <BottomLine>
                <BottomLineContainer>
                  <Copyright>&copy; Copyright. All rights reserved.</Copyright>{' '}
                  <Terms>
                    {policyLink && <Link to={policyLink}>Privacy policy</Link>}
                  </Terms>
                </BottomLineContainer>
              </BottomLine>
            </Root>
          );
        },
        query,
        [LinkFragment],
      )}
    </StaticQuery>
  );
}

Footer.propTypes = {
  logo: PropTypes.node.isRequired,
  // navigation: PropTypes.arrayOf(PropTypes.object),
};

// Footer.defaultProps = {
//   navigation: [{}],
// };

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import { linkResolver } from '@digitaliseringsbyran/gatsby-source-prismic-graphql';
import { RichText } from 'prismic-reactjs';

// import HeroImage from '../../slices/HeroImage';
// import HeroVideo from '../../slices/HeroVideo';
import Image from '../Image';
import ColumnWithButton from '../../slices/ColumnWithButton';
import ImageGallery from '../../slices/ImageGallery';

function Slice({ slice }) {
  switch (slice.type) {
    // case 'paragraph':
    //   return <p key={key}>{slice.text}</p>;
    // case 'heading1':
    // case 'heading2':
    // case 'heading3':
    // case 'heading4':
    // case 'heading5':
    // case 'heading6':
    //   return (
    //     <RichText
    //       render={slice.text}
    //       linkResolver={linkResolver}
    //     />
    //   );
    case 'text':
      return (
        <RichText render={slice.primary.text} linkResolver={linkResolver} />
      );
    case 'quote':
      return (
        <blockquote>
          <RichText render={slice.primary.quote} linkResolver={linkResolver} />
        </blockquote>
      );
    case 'image':
      if (!slice.primary.image) {
        return null;
      }

      return (
        <Image
          fluid={slice.primary.imageSharp?.childImageSharp.fluid}
          fallback={slice.primary.image}
        />
      );
    case 'image_gallery':
      return <ImageGallery data={slice} />;
    // case 'image_item':
    //   return <HeroImage data={slice} />;
    // case 'video_item':
    //   return <HeroVideo data={slice} />;
    case 'column_with_button':
      return <ColumnWithButton data={slice} />;
    default:
      return null;
  }
}

Slice.propTypes = {
  slice: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Slice;

import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment LinkFragment on PRISMIC__Linkable {
    _linkType
    ... on PRISMIC__ExternalLink {
      _linkType
      url
    }
    ... on PRISMIC__FileLink {
      _linkType
      name
      url
    }
    ... on PRISMIC__ImageLink {
      _linkType
      name
      url
    }
    ... on PRISMIC_Client {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Partners {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Partner {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Articles {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Article {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Page {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Clients {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Client {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Contact {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
    ... on PRISMIC_Homepage {
      title
      _linkType
      _meta {
        uid
        type
      }
    }
  }
`;

export default Fragment;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Root = styled.nav`
  ${({ direction }) =>
    direction === 'horizontal'
      ? `
      display: flex;
      align-items: center;
      flex-flow: wrap;
    `
      : `
      display: flex;
      flex-direction: column;
  `}

  ${({ align }) => {
    let alignment = '';

    switch (align) {
      case 'center':
        alignment = 'center';
        break;
      case 'end':
        alignment = 'flex-end';
        break;
      case 'start':
      default:
        alignment = 'flex-start';
        break;
    }

    return `justify-content: ${alignment};`;
  }}
`;

const HorizontalItem = styled(Link)`
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.item.colorNegative
      : theme.components.menu.item.color};
  font-weight: ${(props) => props.theme.components.menu.item.fontWeight};
  font-size: ${(props) => props.theme.components.menu.item.fontSize};
  letter-spacing: ${(props) => props.theme.components.menu.item.letterSpacing};
  text-transform: ${(props) => props.theme.components.menu.item.textTransform};
  padding: ${(props) => props.theme.components.menu.item.padding};

  &:hover {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.item.hoverNegative
        : theme.components.menu.item.hover};
  }

  &.active {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const VerticalItem = styled(Link)`
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.itemVertical.colorNegative
      : theme.components.menu.itemVertical.color};
  font-weight: ${(props) =>
    props.theme.components.menu.itemVertical.fontWeight};
  font-size: ${(props) => props.theme.components.menu.itemVertical.fontSize};
  letter-spacing: ${(props) =>
    props.theme.components.menu.itemVertical.letterSpacing};
  text-transform: ${(props) =>
    props.theme.components.menu.itemVertical.textTransform};
  padding: ${(props) => props.theme.components.menu.itemVertical.padding};

  &:hover {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.itemVertical.hoverNegative
        : theme.components.menu.itemVertical.hover};
  }

  &.active {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

function Menu({ items, children, align, direction, color }) {
  const Item = direction === 'horizontal' ? HorizontalItem : VerticalItem;
  return (
    <Root align={align} direction={direction}>
      {items.map((item) => (
        <Item
          key={item.label}
          to={item.link}
          color={color}
          activeClassName="active"
          partiallyActive={false}
        >
          {item.label}
        </Item>
      ))}
      {children}
    </Root>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
  align: PropTypes.oneOf(['start', 'center', 'end']),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  color: PropTypes.oneOf(['default', 'negative', 'inline']),
};

Menu.defaultProps = {
  children: '',
  align: 'start',
  direction: 'horizontal',
  items: [
    {
      link: '/test',
      label: 'Test',
    },
  ],
  color: 'default',
};

export default Menu;
